<template>
  <v-toolbar
      v-if="isMobile"
      color="primary">
    <v-container>
      <v-btn
          aria-label="Acessar Tela Inicial"
          class="h-100"
          href="/home"
          variant="text">
        <NuxtImg
            alt="Logo Sempre Internet"
            format="webp"
            height="75"
            placeholder
            src="/sempre.webp"></NuxtImg>
      </v-btn>
    </v-container>
    <v-btn
        aria-label="Abrir menu"
        size="large"
        variant="text"
        @click="toggleMenuMobile">
      <v-icon>
        {{ menuMobileAtivo ? 'mdi-close' : 'mdi-menu' }}
      </v-icon>
    </v-btn>
  </v-toolbar>
  <v-toolbar
      v-else
      color="primary"
      density="default"
      height="100">
    <v-row>
      <v-col
          class="d-flex justify-center align-center"
          cols="12"
          md="6"
      >
        <v-container class="d-flex justify-center">
          <v-btn
              aria-label="Acessar Tela Inicial"
              class="h-100"
              href="/home"
              variant="text">
            <NuxtImg
                alt="Logo Sempre Internet"
                format="webp"
                height="48"
                placeholder
                src="/logo-sempre-negativa.webp"
                width="200">
            </NuxtImg>
          </v-btn>
        </v-container>
      </v-col>
      <v-col
          class="d-flex justify-center align-center"
          cols="12"
          md="6"
      >
        <LazyMenuNavegacao :menu-itens="opcoesMenuComItens"></LazyMenuNavegacao>
      </v-col>
    </v-row>
  </v-toolbar>
  <LazyMenuNavegacaoMobile
      v-if="menuMobileAtivo && isMobile"
      :menu-itens="opcoesMenuComItens"/>
</template>
<script lang="ts">
import type {NavItem, OpcaoMenu} from "~/pages/home-cliente.vue";

export default defineComponent({
  emits: ['menuMobileChange'],
  data() {
    return {
      menuMobileAtivo: false,
      navItemsParaResidencia: [
        {
          titulo: 'Planos Fibra por Cidade',
          url: '/promocao/ads/escolha-sua-cidade'
        }
      ] as NavItem[],
      navItemsParaEmpresa: [
        {
          titulo: 'Banda Larga PJ',
          url: 'https://conteudo.sempreinternet.com.br/banda-larga-pj'
        },
        {
          titulo: 'Corporativo',
          url: 'https://conteudo.sempreinternet.com.br/corporativo'
        },
      ] as NavItem[],
      navItemsInstitucional: [
        {
          titulo: 'Conheça a Sempre',
          url: '/institucional'
        },
        {
          titulo: 'Nossas Lojas',
          url: '/nossas-lojas'
        },
        {
          titulo: 'Trabalhe conosco',
          url: 'https://trabalhenasempre.solides.jobs/'
        }
      ] as NavItem[],
      navItemsAjuda: [
        {
          titulo: 'Canais de Atendimento',
          url: '/canais-de-atendimento'
        },
        {
          titulo: 'Política de Privacidade',
          url: '/politica-de-privacidade'
        },
        {
          titulo: 'Regulamentos e Condições Gerais',
          url: '/regulamentos'
        },
        {
          titulo: 'FAQ',
          url: '/faq'
        }
      ] as NavItem[],
      navItemsSac: [
        {
          titulo: '2ª via de boleto',
          url: 'https://central.sempre.hubsoft.com.br/central/fatura'
        },
        {
          titulo: 'Negociar Débitos',
          url: 'https://central.sempre.hubsoft.com.br/central/fatura'
        },
        {
          titulo: 'Solicitar Desbloqueio',
          url: 'https://central.sempre.hubsoft.com.br/central/servico'
        },
        {
          titulo: 'Teste de Velocidade',
          url: 'https://www.speedtest.net/'
        },
        {
          titulo: 'Regulamentos e condições gerais',
          url: '/regulamentos'
        },
        {
          titulo: 'Ouvidoria',
          url: '/ouvidoria'
        }
      ] as NavItem[],
      opcoesMenu: [
        {titulo: 'INSTITUCIONAL'},
        {titulo: 'PARA RESIDÊNCIA'},
        {titulo: 'PARA EMPRESA'},
        {titulo: 'AJUDA'}
      ] as OpcaoMenu[],
    }
  },
  methods: {
    toggleMenuMobile() {
      this.menuMobileAtivo = !this.menuMobileAtivo;
      this.$emit('menuMobileChange', this.opcoesMenuComItens);
    }
  },
  computed: {
    opcoesMenuComItens(): OpcaoMenu[] {
      return this.opcoesMenu.map(opcao => {
        switch (opcao.titulo) {
          case 'INSTITUCIONAL':
            return {...opcao, itens: this.navItemsInstitucional};
          case 'PARA RESIDÊNCIA':
            return {...opcao, itens: this.navItemsParaResidencia};
          case 'PARA EMPRESA':
            return {...opcao, itens: this.navItemsParaEmpresa};
          case 'AJUDA':
            return {...opcao, itens: this.navItemsAjuda};
          default:
            return opcao;
        }
      });
    },

    isMobile() {
      return this.$vuetify.display.smAndDown;
    }
  },
})
</script>
<style scoped>
</style>
